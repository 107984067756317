*, *::before, *::after {
  box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  font-size: 16px;
  min-height: 100vh;
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;
}

h1, h2, h3, h4, h5, h6 {
  color: #595a5c;
  font-weight: 700;
  text-transform: uppercase;
}

a, a:visited, a:active, a:hover {
  color: #595a5c;
}

a, a:visited, a:active {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
